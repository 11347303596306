<template>
  <div id="dealsSelector" class="contentBlock" v-if="demandsList.length > 1">
<!--  <div id="dealsSelector" class="contentBlock">-->
    <div class="title">
      <h3>Список договоров <span class=clickTxt v-on:click="cdView">({{textForClick}})</span></h3>
    </div>
    <div class="body">
      <ul >
        <li v-for="(item,index) in demandsListView"
            v-bind:class="{
                hover: item.demandStatusInt === 0 || item.demandStatusInt === 2,
                active: ActiveDemand === item.demand
              }"
            v-on:click="tryClick(item)"
            :key="index"
            class="descriptionData"
        >
          <p>№ {{item.demand}} - {{item.demandStatusTxt}} <span class="clickTxt" v-on:click="updateDeal"
                                                                v-if="item.demandStatusInt === 2">Обновить
            информацию</span></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {mapGetters,mapState} from 'vuex';
  export default {
    name: "dealsSelect",
    data(){
      return {
        shortView:true,
      }
    },
    methods:{
      tryClick(item){
        if (item.demandStatusInt === 0) {
          this.$store.commit('setActiveDemand', item.demand);
        }
      },
      cdView(){
        this.shortView = !this.shortView;
      },
      updateDeal(){
        this.$store.dispatch('updateContentData',true).catch(res=>alert(res));
      }
    },
    computed: {
      ...mapState({
        ActiveDemand:state=>state.contentData.deals.activeDemand
      }),
      ...mapGetters([
        'demandsList',
      ]),
      textForClick(){
        return this.shortView ? 'Показать все': 'Показать активные';
      },
      demandsListView(){
        let out = [];
        for (let i=0;i<this.demandsList.length;i+=1){
          if (this.shortView){
            if (this.demandsList[i].demandStatusInt === 0 || this.demandsList[i].demandStatusInt ===
              2){
            out.push(this.demandsList[i])
          }
        }else {
            out.push(this.demandsList[i])
          }
      }
        return out;
      },
    },
  }
</script>

<style scoped>
 #dealsSelector .body { padding-bottom: 5vw }
  ul > li.hover{cursor: pointer;}
  ul > li.hover:hover{color:#39B54A}
  ul > li.hover:hover:after{background-color:#39B54A}
  ul > li.active{ color:#0f5740; }
  ul > li.active:after{background-color:#0f5740}
  ul > li{cursor: default;transition: all 0.3s ease-out}
  ul > li.hover:after{transition: all 0.3s ease-out}  
  .clickTxt{cursor: pointer;font-style: italic;color:#0f5740;transition: all 0.3s ease-out}
  .clickTxt:hover{color:#39B54A}
  @media screen and (min-width: 760px){
    ul > li > p{ font-size: 3.5vw;}
  }
  @media screen and (min-width: 1239px){
    ul > li > p{ font-size: 16px;}

    #dealsSelector .body { padding-bottom: 17px }
  }
</style>
